import React, { Component } from "react"
import { Link } from "gatsby"
// Components

// Styles
import styles from "./scss/navigation.module.scss"
import "./scss/hamburger.css"

class Navigation extends Component {
  state = {
    toggle: false,
  }

  handleClick = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle,
    }))
  }

  closeMenu = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle,
    }))
  }

  render() {
    const { toggle } = this.state

    return (
      <nav className={styles.nav}>
        <div className={styles.top}>
          <Link to="/" className={styles.logo}>
            <h3>RG</h3>
          </Link>
          <button
            onClick={this.handleClick}
            className={`hamburger hamburger--3dx ${toggle ? "is-active" : ""}`}
            type="button"
            aria-expanded={toggle ? "true" : "false"}
            aria-label="Open Menu"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <ul className={`${styles.ulLinks} ${toggle ? styles.ulOpen : ""}`}>
          <li>
            <Link to="/#about" onClick={this.closeMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/#work" onClick={this.closeMenu}>
              Work
            </Link>
          </li>
          <li>
            <Link to="/#contact" onClick={this.closeMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}
export default Navigation
