import React from "react"
import Navigation from "../components/navigation"
import Footer from "./footer"
import layoutStyles from "./scss/layout.module.scss"

const Layout = ({ children }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', { speed: 1000 })
  }
  return (
    <div className={layoutStyles.container}>
      <Navigation />
      <div className={layoutStyles.content}>{children}</div>
      <Footer />
    </div>
  )
}
export default Layout
