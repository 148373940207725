import React from "react"
import { getDate } from "../util/utilityFunctions"
import styles from "./scss/footer.module.scss"
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a href="mailto:robbie@robbiegalvin.com">robbie@robbiegalvin.com</a>
      <span>&copy; Robbie Galvin {getDate()} </span>

      <a
        className={styles.dazzle}
        href="https://www.dazzleworks.ie"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Built by
        <span> dazzle works</span>
      </a>
    </footer>
  )
}

export default Footer
